// dependency libraries
import React from 'react'
import { useIntl } from 'react-intl'

// root modules
import { FormattedMessage } from 'utils/intl'

// sibling modules
import { Title, Desc } from './styles'

const QLUE_SAAS_WEBSITE_URL = process.env.GATSBY_HOSTNAME

function PrivacyPolicyDomain() {
  const intl = useIntl()
  return (
    <>
      <Title><FormattedMessage id="7f91830c5" /></Title>
      <Desc>
        <FormattedMessage id="This-Privacy" />{ ' ' }
        <a href={ QLUE_SAAS_WEBSITE_URL } target="_blank" rel="noreferrer">
          { QLUE_SAAS_WEBSITE_URL }{ ' ' }
        </a>{ ' ' }
        <FormattedMessage id="Wesbsite-Site" />
      </Desc>
      <br />

      <Title fontSize="16px" data-target="personal-and">
        { intl.formatMessage({ id: 'Personal-and' }) }
      </Title>
      <Desc>
        <FormattedMessage id="Qlue-may" />
      </Desc>
      <br />

      <Title fontSize="16px" data-target="web-browser">
        <FormattedMessage id="Web-browser" />
      </Title>
      <Desc>
        <FormattedMessage id="Our-site" />
      </Desc>
      <br />

      <Title fontSize="16px" data-target="qlue-protects">
        <FormattedMessage id="Qlue-protects" />
      </Title>
      <Desc>
        <FormattedMessage id="Qlue-adopts" />{ ' ' }
        <a
          href="https://jdih.kemenkeu.go.id/fullText/2008/11TAHUN2008UU.htm"
          target="_blank"
          rel="noreferrer"
        >
          https://jdih.kemenkeu.go.id/fullText/2008/11TAHUN2008UU.htm
        </a>
        .
      </Desc>
      <br />

      <Title fontSize="16px" data-target="changes-to">
        <FormattedMessage id="Changes-to" />
      </Title>
      <Desc>
        <FormattedMessage id="Qlue-has" />
      </Desc>
      <br />

      <Title fontSize="16px" data-target="contacting-us">
        <FormattedMessage id="Contacting-us" />
      </Title>
      <Desc>
        <FormattedMessage id="If-you" />
        <br />
        <br />
        PT. Qlue Performa Indonesia
        <br />
        Jl. Pejaten Barat No. 13
        <br />
        Pasar Minggu, Jakarta Selatan
        <br />
        12510
        <br />
        <br />
        { /* Phone: (+62 21) 71793635
        <br /> */ }
        Email:{ ' ' }
        <a href="mailto:info@qlue.id" target="_blank" rel="noreferrer">
          info@qlue.id
        </a>
      </Desc>
    </>
  )
}

export default PrivacyPolicyDomain
