export const TITLE_NAME = (intl) => ({
  termService   : { name: intl.formatMessage({ id: 'terms-of-service' }), path: '/term-service' },
  privacyPolicy : { name: intl.formatMessage({ id: '7f91830c5' }), path: '/privacy-policy' }
})

export const LEFT_ITEMS = (intl) => ({
  privacyPolicy: [
    { id: 'personal-and', name: intl.formatMessage({ id: 'Personal-and' }) },
    { id: 'web-browser', name: intl.formatMessage({ id: 'Web-browser' }) },
    { id: 'qlue-protects', name: intl.formatMessage({ id: 'Qlue-protects' }) },
    { id: 'changes-to', name: intl.formatMessage({ id: 'Changes-to' }) },
    { id: 'contacting-us', name: intl.formatMessage({ id: 'Contacting-us' }) }
  ],
  faq: ['Question 1']
})
