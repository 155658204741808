import styled, { css } from "styled-components";

import { darkBlueGrey } from "components/baseColor";

export const Wrapper = styled.div`
  padding: 6.667em 9em;

  @media (max-width: 414px) {
    padding: 6.6em 3em;
  }
`;

export const Title = styled.h1`
  font-size: 50px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 2px;
  color: ${darkBlueGrey};
  margin: 0;
  margin-bottom: 1.2em;
  text-transform: uppercase;

  @media (max-width: 414px) {
    font-size: 20px;
  }
`;

export const WrapperContent = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;

  @media (max-width: 414px) {
    display: flex;
    flex-direction: column;
  }
`;

export const LeftItems = styled.div`
  max-width: 16em;
  margin-right: 4em;

  @media (max-width: 414px) {
    // max-height: 10em;
    display: flex;
    flex-direction: column;
  }
`;

LeftItems.Title = styled.div`
  font-size: 1.3em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${darkBlueGrey};
  padding: 11px 15px;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: rgba(34, 43, 73, 0.05);
    `}

  @media (max-width: 414px) {
    font-size: 1em;
  }
`;

LeftItems.Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

LeftItems.Li = styled.li`
  padding: 1.167em 2.5em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${darkBlueGrey};
  cursor: pointer;
`;
