// dependency libraries
import React from 'react'

// root modules
import PrivacyPolicy from 'domains/PrivacyPolicy'
import TermsView from 'domains/TermsView'
import SEO from 'components/seo'

export default function PrivacyPolicyPage(props) {
  return (
    <>
      <SEO
        title="Beyond Innovation, Driving Impact"
        description="Qlue provides comprehensive smart city solutions focusing on Artificial Intelligence (AI), Internet of Things (IoT), and mobile workforce technology"
      />
      <TermsView location={ props.location } keyName="privacyPolicy" uri={ props.uri }>
        <PrivacyPolicy { ...props } />
      </TermsView>
    </>
  )
}
