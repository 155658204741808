// dependency libraries
import React, { Fragment, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useIntl } from 'react-intl'

// root modules
import Layout from 'components/Layout'
import { isBrowser } from 'utils'

// sibling modules
import { Wrapper, LeftItems, Title, WrapperContent } from './styles'
import { TITLE_NAME, LEFT_ITEMS } from './contants'

function TermsView({ children, keyName, uri, location }) {
  const intl = useIntl()
  const LIST_TITLE = Object.keys(TITLE_NAME(intl))

  function onClickMenuItem(id) {
    return () => {
      navigate(`${uri}/#${id}`)
    }
  }

  function onClickTitle(data) {
    return () => {
      navigate(`${data.path}`)
    }
  }

  useEffect(() => {
    if (isBrowser) {
      const { hash } = location
      const name = hash.replace('#', '')
      const getEle = document.querySelector(`[data-target="${name}"]`)
      const getPosition = getEle?.getBoundingClientRect().top + window.scrollY

      if (!isNaN(getPosition)) {
        window.scroll({
          top      : getPosition - 100,
          behavior : 'smooth'
        })
      }
    }
  }, [location])

  return (
    <Layout>
      <Wrapper>
        <Title>{ TITLE_NAME(intl)[keyName].name }</Title>
        <WrapperContent>
          <LeftItems>
            { LIST_TITLE.map((key, i) => (
              <Fragment key={ i }>
                <LeftItems.Title onClick={ onClickTitle(TITLE_NAME(intl)[key]) } isActive={ key === keyName }>
                  { TITLE_NAME(intl)[key].name }
                </LeftItems.Title>
                { key === keyName && (
                  <LeftItems.Ul>
                    { (LEFT_ITEMS(intl)[key] ?? []).map((item, i) => (
                      <LeftItems.Li onClick={ onClickMenuItem(item.id) } key={ i }>
                        { item.name }
                      </LeftItems.Li>
                    )) }
                  </LeftItems.Ul>
                ) }
              </Fragment>
            )) }
          </LeftItems>
          <div>{ children }</div>
        </WrapperContent>
      </Wrapper>
    </Layout>
  )
}

export default TermsView
